import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';
import { Context as homeContext } from '../../context/homeContext';
// import { Context as loadContext } from '../../context/loadContext';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Intro = ({
  className,
  sections,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = e => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = e => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );
  const {
    state: { intro },
    fetchIntroData,
  } = useContext(homeContext);
  //  const { setIntroLoad } = useContext(loadContext);
  //  if (intro) {
  //    setIntroLoad();
  //  }
  useEffect(() => {
    fetchIntroData();
  }, []);
  const introSection =
    sections &&
    sections.length &&
    sections.filter(item => item.type === 'intro')[0];

  return (
    <section {...props} className={outerClasses}>
      {introSection && (
        <div className="container-sm">
          <div className={innerClasses}>
            {intro && (
              <Fade bottom>
                <div
                  className="hero-figure illustration-element-01"
                  data-reveal-value="20px"
                  data-reveal-delay="800"
                >
                  <a
                    data-video={intro.video}
                    href="#0"
                    aria-controls="video-modal"
                    onClick={openModal}
                  >
                    <Image
                      className="has-shadow"
                      src={intro.image}
                      alt="Intro"
                      width={896}
                      height={504}
                    />
                  </a>
                </div>
                <Modal
                  id="video-modal"
                  show={videoModalActive}
                  handleClose={closeModal}
                  video={intro.video}
                  videoTag="iframe"
                />
              </Fade>
            )}
            <div className="hero-content">
              <Fade bottom duration={200} delay={200}>
                <h1 className="mt-0 mb-16">
                  <span className="text-color-primary">
                    {introSection.name}
                  </span>
                </h1>
              </Fade>
              <div className="container-xs">
                <Fade bottom duration={200} delay={400}>
                  <p className="m-0 mb-32">{introSection.text}</p>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;
