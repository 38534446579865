import axios from 'axios';

const getQuery = params => {
  const queryList = Object.keys(params).reduce((acc, key) => {
    const param = params[key].reduce((acc, value) => {
      acc.push(`${key}=${value}`);
      return acc;
    }, []);

    acc.push(param.join('&'));
    return acc;
  }, []);
  return queryList.join('&');
};

export const loadUserDetails = async () => {
  const response = await axios.get('/api/v1/users/me/');
  return response.data;
};

export const logOutUser = async () => {
  const response = await axios.post('/accounts/logout/');
  return response.data;
};
export const loadEducationDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/resume/education/?is_active=${isActive}`,
  );
  return response.data;
};

export const loadExperienceDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/resume/experience/?is_active=${isActive}`,
  );
  return response.data;
};

export const loadProfileDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/resume/profile/?is_active=${isActive}`,
  );
  return response.data.length > 0 ? { profile: response.data[0] } : false;
};

export const loadSkillsDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/resume/skills/?is_active=${isActive}`,
  );
  return response.data;
};

export const loadTechnicalSkillsDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/resume/technical-skills/?is_active=${isActive}`,
  );
  return response.data;
};

export const loadRecommendationsDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/home/recommendations/?is_active=${isActive}`,
  );
  return response.data;
};

export const loadSectionsDetails = async params => {
  const query = params ? getQuery(params) : null;
  const response = await axios.get(
    `/api/v1/home/sections/${query ? `?${query}` : ''}`,
  );
  return response.data;
};

export const loadPortfolioDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/home/portfolio/?is_active=${isActive}`,
  );
  return response.data;
};

export const loadCoreValuesDetails = async (isActive = true) => {
  const response = await axios.get(
    `/api/v1/home/core-values/?is_active=${isActive}`,
  );
  return response.data;
};
export const loadIntroDetails = async (isActive = true) => {
  const response = await axios.get(`/api/v1/home/intro/?is_active=${isActive}`);
  return response.data.length > 0 ? { intro: response.data[0] } : false;
};

export const loadLinks = async params => {
  const query = params ? getQuery(params) : null;
  const response = await axios.get(`/api/v1/links/${query ? `?${query}` : ''}`);
  return response.data;
};

export const loadBlogPosts = async params => {
  const query = params ? getQuery(params) : null;
  const response = await axios.get(
    `/api/v1/blog/posts/${query ? `?${query}` : ''}`,
  );
  return response.data;
};

export const loadBlogMainDetails = async params => {
  const query = params ? getQuery(params) : null;
  const response = await axios.get(
    `/api/v1/blog/details/${query ? `?${query}` : ''}`,
  );
  return response.data.results.length > 0
    ? { blogMain: response.data.results[0] }
    : false;
};

export const loadBlogPostDetails = async id => {
  const response = await axios.get(`/api/v1/blog/posts/${id}/`);
  return response.data;
};

export const loadPrivacyPolicyDetails = async params => {
  const query = params ? getQuery(params) : null;
  const response = await axios.get(
    `/api/v1/policy/${query ? `?${query}` : ''}`,
  );
  return response.data.results.length > 0
    ? { privacy: response.data.results[0] }
    : false;
};

const getCookie = name => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const csrftoken = getCookie('csrftoken');
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
