import React, { useContext, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';
import { Context as homeContext } from '../../context/homeContext';
// import { Context as loadContext } from '../../context/loadContext';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import CoreValue from './partials/CoreValue';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const CoreValues = ({
  className,
  sections,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left',
  );
  const {
    state: { coreValues },
    fetchCoreValuesData,
  } = useContext(homeContext);
  useEffect(() => {
    fetchCoreValuesData();
  }, []);
  const coreValuesSection =
    sections &&
    sections.length &&
    sections.filter(item => item.type === 'core_value')[0];
  //  const { setCoreValuesLoad } = useContext(loadContext);
  //  if (coreValues) {
  //    setCoreValuesLoad();
  //  }

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={coreValuesSection} className="center-content" />
          <Fade bottom cascade duration={500} delay={200}>
            <div className={tilesClasses}>
              {coreValues.length &&
                coreValues.map(item => <CoreValue data={item} />)}
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

CoreValues.propTypes = propTypes;
CoreValues.defaultProps = defaultProps;

export default CoreValues;
