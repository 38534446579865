import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3']),
};

const defaultProps = {
  children: null,
  tag: 'h2',
};

const SectionHeader = ({ className, data, children, tag, ...props }) => {
  const classes = classNames('section-header', className);

  const Component = tag;
  const fadeProps = { bottom: true, delay: 200 };
  return (
    <Fade {...fadeProps}>
      {data && (data.name || data.text) && (
        <div {...props} className={classes}>
          <div className="container-xs">
            {children}
            {data.name && (
              <Component
                className={classNames('mt-0', data.text ? 'mb-16' : 'mb-0')}
              >
                {data.name}
              </Component>
            )}
            {data.text && <p className="m-0">{data.text}</p>}
          </div>
        </div>
      )}
    </Fade>
  );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
