import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { Context as userContext } from '../context/userContext';
import { SectionSplitProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Policy = (
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
) => {
  const location = useLocation();
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );
  const {
    state: { privacy },
    fetchPrivacyPolicyData,
  } = useContext(userContext);
  useEffect(() => {
    fetchPrivacyPolicyData();
  }, []);
  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div {...props} className="section-header">
            <div className="container-m">
              {privacy && (
                <>
                  <h2 className={classNames('mt-0 mb-16')}>
                    {location.pathname === '/privacy/ua' ||
                    location.pathname === '/privacy/ua/'
                      ? privacy.name_ua
                      : privacy.name}
                  </h2>
                  <p className="m-0 post-text" style={{ textAlign: 'inherit' }}>
                    <ReactMarkdown
                      children={
                        location.pathname === '/privacy/ua' ||
                        location.pathname === '/privacy/ua/'
                          ? privacy.policy_text_ua
                          : privacy.policy_text
                      }
                    />
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Policy.propTypes = propTypes;
Policy.defaultProps = defaultProps;

export default Policy;
