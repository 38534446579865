import React, { useContext, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';
import { Context as homeContext } from '../../context/homeContext';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import PortfolioItem from './partials/PortfolioItem';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Portfolio = ({
  className,
  sections,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top',
  );

  const {
    state: { portfolio },
    fetchPortfolioData,
  } = useContext(homeContext);
  useEffect(() => {
    fetchPortfolioData();
  }, []);
  const portfolioSection =
    sections &&
    sections.length &&
    sections.filter(item => item.type === 'portfolio')[0];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={portfolioSection} className="center-content" />
          <Fade bottom delay={500}>
            <div className={splitClasses}>
              {portfolio.length &&
                portfolio.map(item => (
                  <PortfolioItem data={item} imageFill={imageFill} />
                ))}
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

Portfolio.propTypes = propTypes;
Portfolio.defaultProps = defaultProps;

export default Portfolio;
