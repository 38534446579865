import React from 'react';

const IntegrityIcon = () => (
  <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <g>
      <title>Integrity</title>
      <path
        strokeWidth="2"
        fill="#FFF"
        d="m23.0376,24.84781c-1.51829,1.49254 -1.78623,2.5461 -1.33967,5.00441c0.62518,4.03864 2.3221,5.88237 2.3221,2.5461c0,-1.31695 0.44655,-2.37051 0.89311,-2.37051c0.53587,0 0.89312,0.87797 0.89312,1.93153c0,1.75593 0.26793,1.66814 3.1259,-1.05356c3.57245,-3.42407 5.35868,-2.89729 2.14347,0.70237c-2.05416,2.28271 -2.05416,2.28271 0.71449,-0.17559c3.75108,-3.33627 4.91213,-2.19491 1.51829,1.49254l-0.17862,0.70237c3.21521,-2.80949 4.37626,-1.66814 1.51829,1.40475c-1.16105,1.31695 -1.42898,1.84373 -0.53587,1.22915c2.94728,-2.28271 2.76865,-0.0878 -0.26793,2.98509c-1.78623,1.75593 -2.50072,3.07288 -1.78623,3.07288c2.23278,0 9.64563,-8.42847 10.27082,-11.67694c1.07174,-5.61898 -3.1259,-9.13084 -8.12734,-6.84813c-1.51829,0.70237 -2.76866,0.70237 -3.9297,0.0878c-2.59003,-1.40475 -5.18006,-1.05356 -7.23423,0.96576z"
      />
    </g>
  </svg>
);

export default IntegrityIcon;
