import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider as LinksProvider } from './context/linksContext';
import { Provider as UserProvider } from './context/userContext';
import { Provider as ResumeProvider } from './context/resumeContext';
import { Provider as HomeProvider } from './context/homeContext';
import { Provider as BlogProvider } from './context/blogContext';
import { Provider as LoadProvider } from './context/loadContext';

import App from './App';

import './assets/scss/style.scss';

const history = createBrowserHistory();
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2e15f074a00743cb8393aee53bd7dc7c@o981101.ingest.sentry.io/5935640',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <Router history={history}>
    <LoadProvider>
      <LinksProvider>
        <UserProvider>
          <ResumeProvider>
            <HomeProvider>
              <BlogProvider>
                <App />
              </BlogProvider>
            </HomeProvider>
          </ResumeProvider>
        </UserProvider>
      </LinksProvider>
    </LoadProvider>
  </Router>,
  document.getElementById('root'),
);
