import React from 'react';

const CuriosityIcon = () => (
  <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <title>Curiosity</title>
    <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        d="M39 43v-6.3a8.97 8.97 0 003-6.569A9.1 9.1 0 0033.262 21 9 9 0 0024 30v1l-2 5 2 1v4a2 2 0 002 2h4a5 5 0 005-5v-5"
        stroke="#FFF"
      />
      <circle stroke="#ACADFF" cx="35" cy="30" r="3" />
    </g>
  </svg>
);

export default CuriosityIcon;
