import React from 'react';

const CommunicationIcon = () => (
  <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <title>Communication</title>
    <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        d="M38 24v-2M40.121 24.879l1.415-1.415M41 27h2M40.121 29.121l1.415 1.415M38 30v2M35.879 29.121l-1.415 1.415M35 27h-2M35.879 24.879l-1.415-1.415"
        stroke="#ACADFF"
      />
      <circle stroke="#ACADFF" cx="38" cy="27" r="3" />
      <path
        d="M29.86 23.18C24.8 23.988 21 27.6 21 32c0 5 4.9 9 11 9a10.55 10.55 0 003.1-.4L41 43l-.6-5.2a9.125 9.125 0 001.991-2.948"
        stroke="#FFF"
      />
    </g>
  </svg>
);
export default CommunicationIcon;
