import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

const propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    author_job_title: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  num: PropTypes.number,
};

const Recommendation = ({ data, num }) => {
  let props = {};
  if ((num + 1) % 3 === 0) {
    props = { delay: 300, right: true };
  } else if ((num + 1) % 2 === 0) {
    props = { delay: 100, bottom: true };
  } else {
    props = { delay: 300, left: true };
  }

  return (
    <Fade {...props} key={data.name}>
      <div className="tiles-item">
        <div className="tiles-item-inner">
          <div className="testimonial-item-content">
            <p className="text-sm mb-0">{data.text}</p>
          </div>
          <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
            <span className="testimonial-item-name text-color-high">
              {data.name}
            </span>
            <span className="text-color-low"> / </span>
            <span className="testimonial-item-link">
              {data.author_job_title}
            </span>
          </div>
        </div>
      </div>
    </Fade>
  );
};

Recommendation.propTypes = propTypes;

export default Recommendation;
