import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  video: PropTypes.string,
  videoTag: PropTypes.oneOf(['iframe', 'video']),
};

const defaultProps = {
  children: null,
  // eslint-disable-next-line react/default-props-match-prop-types
  show: false,
  closeHidden: false,
  video: '',
  videoTag: 'iframe',
};

const Modal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  video,
  videoTag,
  ...props
}) => {
  const keyPress = e => {
    e.keyCode === 27 && handleClose(e);
  };

  const stopProgagation = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', stopProgagation);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', stopProgagation);
    };
  });

  const handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  };

  useEffect(() => {
    handleBodyClass();
  }, [props.show]);

  const classes = classNames(
    'modal',
    show && 'is-active',
    video && 'modal-video',
    className,
  );

  return (
    <>
      {show && (
        <div {...props} className={classes} onClick={handleClose}>
          <div className="modal-inner" onClick={stopProgagation}>
            {video ? (
              <div className="responsive-video">
                {videoTag === 'iframe' ? (
                  <iframe
                    title="video"
                    src={video}
                    frameBorder="0"
                    allowFullScreen
                  />
                ) : (
                  <video v-else controls src={video} />
                )}
              </div>
            ) : (
              <>
                {!closeHidden && (
                  // eslint-disable-next-line react/button-has-type
                  <button
                    className="modal-close"
                    aria-label="close"
                    onClick={handleClose}
                  />
                )}
                <div className="modal-content">{children}</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
