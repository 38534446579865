import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  FacebookLoginButton,
  GoogleLoginButton,
  GithubLoginButton,
  // TwitterLoginButton,
  // InstagramLoginButton,
  // LinkedInLoginButton,
  // TelegramLoginButton,
  // DiscordLoginButton,
} from 'react-social-login-buttons';

const Sign = () => {
  const location = useLocation();
  const signType = location.pathname === '/signup/' ? 'Sign Up' : 'Sign In';
  return (
    <section>
      <div className="container-sm">
        <div className="section-inner">
          <div>
            <h4>{signType} with social account</h4>
            <GoogleLoginButton
              text={`${signType} with Google`}
              onClick={() => {
                window.location.href = '/accounts/google/login/';
              }}
            />
            <FacebookLoginButton
              text={`${signType} with Facebook`}
              onClick={() => {
                window.location.href = '/accounts/facebook/login/';
              }}
            />
            <GithubLoginButton
              text={`${signType} with GitHub`}
              onClick={() => {
                window.location.href = '/accounts/github/login/';
              }}
            />
            {/* <TwitterLoginButton */}
            {/*    text={`${signType} with Twitter`} */}
            {/*  onClick={() => { */}
            {/*    window.location.href = '/accounts/twitter/login/'; */}
            {/*  }} */}
            {/* /> */}
            {/* <InstagramLoginButton */}
            {/*    text={`${signType} with Instagram`} */}
            {/*  onClick={() => { */}
            {/*    window.location.href = '/accounts/instagram/login/'; */}
            {/*  }} */}
            {/* /> */}
            {/* <LinkedInLoginButton */}
            {/*    text={`${signType} with LinkedIn`} */}
            {/*  onClick={() => { */}
            {/*    window.location.href = '/accounts/linkedin/login/'; */}
            {/*  }} */}
            {/* /> */}
            {/* <TelegramLoginButton */}
            {/*    text={`${signType} with Telegram`} */}
            {/*  onClick={() => { */}
            {/*    window.location.href = '/accounts/telegram/login/'; */}
            {/*  }} */}
            {/* /> */}
            {/* <DiscordLoginButton */}
            {/*    text={`${signType} with Discord`} */}
            {/*  onClick={() => { */}
            {/*    window.location.href = '/accounts/discord/login/'; */}
            {/*  }} */}
            {/* /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sign;
