import createDataContext from '../createDataContext';
import {
  loadEducationDetails,
  loadExperienceDetails,
  loadProfileDetails,
  loadSkillsDetails,
  loadTechnicalSkillsDetails,
} from '../../api';

const initialState = {
  education: false,
  experience: false,
  profile: false,
  skills: false,
  technicalSkills: false,
};

const resumeDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_education_data': {
      const { results: education } = action.payload;
      return {
        ...state,
        education,
      };
    }
    case 'set_experience_data': {
      const { results: experience } = action.payload;
      return {
        ...state,
        experience,
      };
    }
    case 'set_profile_data': {
      const { profile } = action.payload;
      return {
        ...state,
        profile,
      };
    }
    case 'set_skills_data': {
      const { results: skills } = action.payload;
      return {
        ...state,
        skills,
      };
    }
    case 'set_technical_skills_data': {
      const { results: technicalSkills } = action.payload;
      return {
        ...state,
        technicalSkills,
      };
    }
    default:
      return { ...state };
  }
};

const fetchEducationData = dispatch => async () => {
  const payload = await loadEducationDetails();
  dispatch({ type: 'set_education_data', payload });
};

const fetchExperienceData = dispatch => async () => {
  const payload = await loadExperienceDetails();
  dispatch({ type: 'set_experience_data', payload });
};

const fetchProfileData = dispatch => async () => {
  const payload = await loadProfileDetails();
  dispatch({ type: 'set_profile_data', payload });
};

const fetchSkillsData = dispatch => async () => {
  const payload = await loadSkillsDetails();
  dispatch({ type: 'set_skills_data', payload });
};

const fetchTechnicalSkillsData = dispatch => async () => {
  const payload = await loadTechnicalSkillsDetails();
  dispatch({ type: 'set_technical_skills_data', payload });
};

export const { Context, Provider } = createDataContext(
  resumeDataReducer,
  {
    fetchEducationData,
    fetchExperienceData,
    fetchProfileData,
    fetchSkillsData,
    fetchTechnicalSkillsData,
  },
  initialState,
);
