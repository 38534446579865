import React from 'react';

const PassionIcon = () => (
  <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <title>Passion</title>
    <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        d="M29 42h10.229a2 2 0 001.912-1.412l2.769-9A2 2 0 0042 29h-7v-4c0-2.373-1.251-3.494-2.764-3.86a1.006 1.006 0 00-1.236.979V26l-5 6"
        stroke="#FFF"
      />
      <path stroke="#ACADFF" d="M22 30h4v12h-4z" />
    </g>
  </svg>
);

export default PassionIcon;
