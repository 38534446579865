import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import Logo from './partials/Logo';
import { Context as userContext } from '../../context/userContext';
import { logOutUser } from '../../api';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const { state: currentUser, fetchUserData } = useContext(userContext);
  const nav = useRef(null);
  const hamburger = useRef(null);

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = `${nav.current.scrollHeight}px`;
    setIsActive(true);
  };

  const keyPress = e => {
    // eslint-disable-next-line no-use-before-define
    isActive && e.keyCode === 27 && closeMenu();
  };

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsActive(false);
  };

  const clickOutside = e => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className,
  );
  useEffect(() => {
    fetchUserData();
  }, []);
  const location = useLocation();

  //  hideSignin = !!(currentUser && (currentUser.email || currentUser.name));
  hideSignin = true;
  const logOut = () => {
    logOutUser().then(() => {
      window.location.href = '/';
    });
  };
  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider',
          )}
        >
          <Logo />
          {!hideNav && (
            <>
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner" />
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames('header-nav', isActive && 'is-active')}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`,
                    )}
                  >
                    {/* {location.pathname !== '/resume/' && ( */}
                    {/* <li> */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {/* <Link to="/resume/">Resume</Link> */}
                    {/* </li> */}
                    {/* )} */}
                    {location.pathname !== '/' && (
                      <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/">Home</Link>
                      </li>
                    )}
                    {location.pathname !== '/blog/' && (
                      <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/blog/">Blog</Link>
                      </li>
                    )}
                    {location.pathname !== '/blog/posts/' && (
                      <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to="/blog/posts/">Posts</Link>
                      </li>
                    )}
                    {currentUser &&
                      (currentUser.isStaff || currentUser.isSuperuser) && (
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            onClick={() => {
                              window.location.href = '/admin/';
                            }}
                          >
                            Admin
                          </Link>
                        </li>
                      )}
                  </ul>
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <Link
                          to="/signin/"
                          className="button button-primary button-wide-mobile button-sm"
                        >
                          Sign in
                        </Link>
                      </li>
                    </ul>
                  )}
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <Link
                          to="/signup/"
                          className="button button-primary button-wide-mobile button-sm"
                        >
                          Sign up
                        </Link>
                      </li>
                    </ul>
                  )}
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link
                          className="button button-primary button-wide-mobile button-sm"
                          onClick={logOut}
                        >
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
