import React, { useContext, useEffect } from 'react';
import '../assets/scss/core/resume/resume.scss';
import { jsPDF } from 'jspdf';
import * as ReactDOMServer from 'react-dom/server';
import { Context as resumeContext } from '../context/resumeContext';

const Resume = () => {
  const ref = React.createRef();
  const {
    state: { education, experience, profile, skills, technicalSkills },
    fetchEducationData,
    fetchExperienceData,
    fetchProfileData,
    fetchSkillsData,
    fetchTechnicalSkillsData,
  } = useContext(resumeContext);
  useEffect(() => {
    fetchEducationData();
    fetchExperienceData();
    fetchProfileData();
    fetchSkillsData();
    fetchTechnicalSkillsData();
  }, []);

  const groupArray = (array, size) => {
    const groupedArray = [[], [], []];
    let cursor = 0;
    for (let i = 0; i < array.length; i++) {
      groupedArray[cursor].push(array[i]);
      if (cursor === size - 1) {
        cursor = 0;
      } else {
        cursor++;
      }
    }
    return groupedArray;
  };
  const groupedTechnicalSkills = technicalSkills.length
    ? groupArray(technicalSkills, 3)
    : false;
  const getResume = isWeb => (
    <div id="doc2" className="yui-t7" ref={ref}>
      <div id="inner">
        <div id="hd">
          <div className="yui-gc">
            <div className="yui-u first">
              {profile && (
                <>
                  <h1>{profile.full_name}</h1>
                  <h2>{profile.job_title}</h2>
                </>
              )}
            </div>

            <div className="yui-u">
              <div className="contact-info">
                {isWeb && (
                  <h3>
                    {/* eslint-disable-next-line no-use-before-define, react/button-has-type */}
                    <button onClick={downloadPdf}>Download PDF</button>
                  </h3>
                )}
                {profile && (
                  <>
                    <h3>
                      <a href="mailto:{profile.email}">{profile.email}</a>
                    </h3>
                    <h3>{profile.phone}</h3>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div id="bd">
          <div id="yui-main">
            <div className="yui-b">
              <div className="yui-gf">
                <div className="yui-u first">
                  <h2>Profile</h2>
                </div>
                <div className="yui-u">
                  {profile && <p className="enlarge">{profile.profile}</p>}
                </div>
              </div>

              <div className="yui-gf">
                <div className="yui-u first">
                  <h2>Skills</h2>
                </div>
                <div className="yui-u">
                  {skills.length &&
                    skills.map(item => (
                      <div className="talent" key={item.name}>
                        <h2>{item.name}</h2>
                        <p>{item.about}</p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="yui-gf">
                <div className="yui-u first">
                  <h2>Technical</h2>
                </div>
                <div className="yui-u">
                  {groupedTechnicalSkills.length &&
                    groupedTechnicalSkills.map((group, key) => (
                      // TODO: Use some random generated key
                      // eslint-disable-next-line react/no-array-index-key
                      <ul className="talent" key={key}>
                        {group.length &&
                          group.map((item, key) => (
                            <li
                              key={item.name}
                              className={key === group.length - 1 ? 'last' : ''}
                            >
                              {item.name}
                            </li>
                          ))}
                      </ul>
                    ))}
                </div>
              </div>

              <div className="yui-gf">
                <div className="yui-u first">
                  <h2>Experience</h2>
                </div>

                <div className="yui-u">
                  {experience.length &&
                    experience.map((item, key) => (
                      <div
                        className={
                          key === experience.length - 1 ? 'job last' : 'job'
                        }
                        key={item.name}
                      >
                        <h2>{item.name}</h2>
                        <h3>{item.job_title}</h3>
                        <h4>{item.studying_period}</h4>
                        <p>{item.about}</p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="yui-gf">
                <div className="yui-u first">
                  <h2>Education</h2>
                </div>
                <div className="yui-u">
                  {education.length &&
                    education.map((item, key) => (
                      <div
                        className={
                          key === experience.length - 1 ? 'job last' : 'job'
                        }
                        key={item.name}
                      >
                        <h2>{item.name}</h2>
                        <h3>{item.specialization}</h3>
                        <h4>{item.studying_period}</h4>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ft">
          {profile && (
            <p>
              {profile.full_name}&mdash;&nbsp;
              <a href="mailto:{profile.email}">{profile.email}</a> &mdash;&nbsp;
              {profile.phone}
            </p>
          )}
        </div>
      </div>
    </div>
  );

  const downloadPdf = () => {
    const resume = getResume(false);
    // eslint-disable-next-line new-cap
    const Doc = new jsPDF();
    const renderedResume = ReactDOMServer.renderToString(resume);
    Doc.html(renderedResume).then(() => Doc.save('resume.pdf'));
  };
  return getResume(true);
};

export default Resume;
