import createDataContext from '../createDataContext';
import {
  loadRecommendationsDetails,
  loadSectionsDetails,
  loadPortfolioDetails,
  loadCoreValuesDetails,
  loadIntroDetails,
} from '../../api';

const initialState = {
  recommendations: false,
  sections: false,
  portfolio: false,
  coreValues: false,
  blogLinks: false,
  intro: false,
};

const homeDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_recommendation_data': {
      const { results: recommendations } = action.payload;
      return {
        ...state,
        recommendations,
      };
    }
    case 'set_sections_data': {
      const { results: sections } = action.payload;
      return {
        ...state,
        sections,
      };
    }
    case 'set_portfolio_data': {
      const { results: portfolio } = action.payload;
      return {
        ...state,
        portfolio,
      };
    }
    case 'set_core_values_data': {
      const { results: coreValues } = action.payload;
      return {
        ...state,
        coreValues,
      };
    }
    case 'set_intro_data': {
      const { intro } = action.payload;
      return {
        ...state,
        intro,
      };
    }
    default:
      return { ...state };
  }
};

const fetchRecommendationsData = dispatch => async () => {
  const payload = await loadRecommendationsDetails();
  dispatch({ type: 'set_recommendation_data', payload });
};

const fetchSectionsData = dispatch => async () => {
  const payload = await loadSectionsDetails({ status: ['active'] });
  dispatch({ type: 'set_sections_data', payload });
};

const fetchPortfolioData = dispatch => async () => {
  const payload = await loadPortfolioDetails();
  dispatch({ type: 'set_portfolio_data', payload });
};

const fetchCoreValuesData = dispatch => async () => {
  const payload = await loadCoreValuesDetails();
  dispatch({ type: 'set_core_values_data', payload });
};
const fetchIntroData = dispatch => async () => {
  const payload = await loadIntroDetails();
  dispatch({ type: 'set_intro_data', payload });
};
export const { Context, Provider } = createDataContext(
  homeDataReducer,
  {
    fetchRecommendationsData,
    fetchSectionsData,
    fetchPortfolioData,
    fetchCoreValuesData,
    fetchIntroData,
  },
  initialState,
);
