import React from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { IconFactory } from '../../../factories';

const propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

const CoreValue = ({ data }) => (
  <Fade bottom>
    <div className="tiles-item" key={data.name}>
      <div className="tiles-item-inner">
        <div className="features-tiles-item-header">
          <div className="features-tiles-item-image mb-16">
            {IconFactory(data.type)}
          </div>
        </div>
        <div className="features-tiles-item-content">
          <h4 className="mt-0 mb-8">{data.name}</h4>
          <p className="m-0 text-sm">{data.about}</p>
        </div>
      </div>
    </div>
  </Fade>
);

CoreValue.propTypes = propTypes;

export default CoreValue;
