import React from 'react';

const ImpactIcon = () => (
  <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <title>Impact</title>
    <g strokeWidth="2" fill="none" fillRule="evenodd">
      <ellipse
        stroke="#ACADFF"
        strokeLinecap="square"
        cx="32"
        cy="32"
        rx="5.5"
        ry="11"
      />
      <path d="M32 21v22M21 32h22" stroke="#FFF" />
      <circle stroke="#FFF" strokeLinecap="square" cx="32" cy="32" r="11" />
    </g>
  </svg>
);

export default ImpactIcon;
