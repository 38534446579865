import createDataContext from '../createDataContext';
import { loadLinks } from '../../api';

const initialState = {
  links: [],
};

const linksDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_social_links_data': {
      const links = action.payload.results;
      return {
        ...state,
        links,
      };
    }
    default:
      return { ...state };
  }
};

const fetchSocialLinksData = dispatch => async () => {
  const payload = await loadLinks({ status: ['active'], type: ['social'] });
  dispatch({ type: 'set_social_links_data', payload });
};

export const { Context, Provider } = createDataContext(
  linksDataReducer,
  {
    fetchSocialLinksData,
  },
  initialState,
);
