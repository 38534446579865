import React, { useContext, useEffect } from 'react';
import { Context as blogContext } from '../context/blogContext';
import BlogPosts from '../components/sections/BlogPosts';

const Posts = () => {
  const {
    state: { blogSection },
    fetchBlogSectionData,
  } = useContext(blogContext);
  useEffect(() => {
    fetchBlogSectionData();
  }, []);
  return <BlogPosts invertMobile topDivider imageFill section={blogSection} />;
};

export default Posts;
