import createDataContext from '../createDataContext';
import { loadUserDetails, loadPrivacyPolicyDetails } from '../../api';

const initialState = {
  name: false,
  email: false,
  pictureUrl: false,
  isStaff: false,
  isSuperuser: false,
  privacy: false,
};

const userDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_user_data': {
      const {
        is_superuser: isSuperuser,
        is_staff: isStaff,
        picture_url: pictureUrl,
        email,
        name,
      } = action.payload;
      return {
        ...state,
        name,
        email,
        pictureUrl,
        isStaff,
        isSuperuser,
      };
    }
    case 'set_privacy_policy_data': {
      const { privacy } = action.payload;
      return {
        ...state,
        privacy,
      };
    }
    default:
      return { ...state };
  }
};

const fetchUserData = dispatch => async () => {
  const payload = await loadUserDetails();
  dispatch({ type: 'set_user_data', payload });
};

const fetchPrivacyPolicyData = dispatch => async () => {
  const payload = await loadPrivacyPolicyDetails({ is_active: [true] });
  dispatch({ type: 'set_privacy_policy_data', payload });
};

export const { Context, Provider } = createDataContext(
  userDataReducer,
  {
    fetchUserData,
    fetchPrivacyPolicyData,
  },
  initialState,
);
