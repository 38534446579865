import React, { useContext, useEffect } from 'react';
import { Context as homeContext } from '../context/homeContext';
// import { Context as loadContext } from '../context/loadContext';
// import sections
import Intro from '../components/sections/Intro';
import CoreValues from '../components/sections/CoreValues';
import Portfolio from '../components/sections/Portfolio';
import Recommendations from '../components/sections/Recommendations';
// import Cta from '../components/sections/Cta';

const Home = () => {
  const {
    state: { sections },
    fetchSectionsData,
  } = useContext(homeContext);
  useEffect(() => {
    fetchSectionsData();
  }, []);
  //  const {
  //    state: { isIntroLoad, isCoreValuesLoad },
  //  } = useContext(loadContext);
  // eslint-disable-next-line no-console
  //  console.log(isIntroLoad, isCoreValuesLoad);
  return (
    <>
      <Intro className="illustration-section-01" sections={sections} />
      <CoreValues sections={sections} />
      <Portfolio invertMobile topDivider imageFill sections={sections} />
      <Recommendations topDivider sections={sections} />
    </>
  );
};
// <Cta split />

export default Home;
