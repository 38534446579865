import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../../elements/Image';

const propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
};

const PortfolioItem = ({ data, imageFill }) => {
  const className = 'split-item-content center-content-mobile';

  return (
    <a href={data.link.url} target="_blank" rel="noreferrer">
      <div className="split-item" key={data.name}>
        <div className={className} data-reveal-container=".split-item">
          <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
            {data.subtitle}
          </div>
          <h3 className="mt-0 mb-12">{data.name}</h3>
          <p className="m-0">{data.about}</p>
        </div>
        <div
          className={classNames(
            'split-item-image center-content-mobile',
            imageFill && 'split-item-image-fill',
          )}
          data-reveal-container=".split-item"
        >
          <Image src={data.image} alt={data.name} width={528} height={396} />
        </div>
      </div>
    </a>
  );
};
PortfolioItem.propTypes = propTypes;

export default PortfolioItem;
