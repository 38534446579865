import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Context as linksContext } from '../../../context/linksContext';
import { IconFactory } from '../../../factories';

const FooterSocial = ({ className, ...props }) => {
  const {
    state: { links },
    fetchSocialLinksData,
  } = useContext(linksContext);
  useEffect(() => {
    fetchSocialLinksData();
  }, []);
  const classes = classNames('footer-social', className);
  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        {links.length &&
          links.map(item => (
            <li key={item.name}>
              <a href={item.url}>{IconFactory(item.name.toLowerCase())}</a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FooterSocial;
