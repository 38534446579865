import createDataContext from '../createDataContext';
import {
  loadBlogPosts,
  loadLinks,
  loadSectionsDetails,
  loadBlogMainDetails,
} from '../../api';

const initialState = {
  posts: false,
  blogSection: false,
  blogLinks: false,
  blogMain: false,
};

const blogDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_blog_posts_data': {
      const posts = action.payload.results;
      return {
        ...state,
        posts,
      };
    }
    case 'set_blog_section_data': {
      const blogSection =
        action.payload.results.length > 0 ? action.payload.results[0] : false;
      return {
        ...state,
        blogSection,
      };
    }
    case 'set_blog_links_data': {
      const { results: blogLinks } = action.payload;
      return {
        ...state,
        blogLinks,
      };
    }
    case 'set_blog_main_data': {
      const { blogMain } = action.payload;
      return {
        ...state,
        blogMain,
      };
    }
    default:
      return { ...state };
  }
};

const fetchBlogPostsData = dispatch => async () => {
  const payload = await loadBlogPosts({
    is_active: [true],
    ordering: ['-published_at'],
  });
  dispatch({ type: 'set_blog_posts_data', payload });
};

const fetchBlogSectionData = dispatch => async () => {
  const payload = await loadSectionsDetails({
    status: ['active'],
    type: ['blog'],
  });
  dispatch({ type: 'set_blog_section_data', payload });
};
const fetchBlogLinks = dispatch => async () => {
  const payload = await loadLinks({ status: ['active'], type: ['blog'] });
  dispatch({ type: 'set_blog_links_data', payload });
};
const fetchBlogMainData = dispatch => async () => {
  const payload = await loadBlogMainDetails({ is_active: [true] });
  dispatch({ type: 'set_blog_main_data', payload });
};

export const { Context, Provider } = createDataContext(
  blogDataReducer,
  {
    fetchBlogPostsData,
    fetchBlogSectionData,
    fetchBlogLinks,
    fetchBlogMainData,
  },
  initialState,
);
