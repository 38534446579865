import React from 'react';

import {
  FacebookIcon,
  GithubIcon,
  LinkedInIcon,
  TwitterIcon,
  SkypeIcon,
  CommunicationIcon,
  CuriosityIcon,
  CourageIcon,
  PassionIcon,
  IntegrityIcon,
  ImpactIcon,
} from '../../components/common/Icons';

const IconFactory = type => {
  switch (type) {
    case 'facebook':
      return <FacebookIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'github':
      return <GithubIcon />;
    case 'linkedin':
      return <LinkedInIcon />;
    case 'skype':
      return <SkypeIcon />;
    case 'communication':
      return <CommunicationIcon />;
    case 'curiosity':
      return <CuriosityIcon />;
    case 'courage':
      return <CourageIcon />;
    case 'passion':
      return <PassionIcon />;
    case 'integrity':
      return <IntegrityIcon />;
    case 'impact':
      return <ImpactIcon />;
    default:
      return null;
  }
};

export default IconFactory;
