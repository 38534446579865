import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Context as blogContext } from '../../context/blogContext';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const BlogMain = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const {
    state: { blogMain, blogLinks },
    fetchBlogMainData,
    fetchBlogLinks,
  } = useContext(blogContext);
  useEffect(() => {
    fetchBlogMainData();
    fetchBlogLinks();
  }, []);
  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {blogMain && (
            <div {...props} className="section-header">
              <div className="container-xs">
                <h2
                  className={classNames(
                    'mt-0',
                    blogMain.text ? 'mb-16' : 'mb-0',
                  )}
                >
                  {blogMain.name}
                </h2>
                {blogMain.image && (
                  <Image
                    className="has-shadow"
                    src={blogMain.image}
                    alt="Intro"
                    width={896}
                    height={504}
                  />
                )}
                <br />
                <p className="m-0" style={{ whiteSpace: 'pre-line' }}>
                  {blogMain.text}
                </p>
                <br />
                <div>
                  <ButtonGroup>
                    <h4>I also publish my posts on hashnode.com and dev.to</h4>

                    {blogLinks.length &&
                      blogLinks.map(item => (
                        <Button
                          tag="a"
                          color="primary"
                          wideMobile
                          href={item.url}
                          key={item.name}
                        >
                          {item.name}
                        </Button>
                      ))}
                  </ButtonGroup>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

BlogMain.propTypes = propTypes;
BlogMain.defaultProps = defaultProps;

export default BlogMain;
