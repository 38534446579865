import React, { useContext, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';
import { Context as blogContext } from '../../context/blogContext';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Post from './partials/Post';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const BlogPosts = ({
  className,
  sections,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top',
  );

  const {
    state: { posts },
    fetchBlogPostsData,
  } = useContext(blogContext);
  useEffect(() => {
    fetchBlogPostsData();
  }, []);

  const blogSection =
    sections &&
    sections.length &&
    sections.filter(item => item.type === 'blog')[0];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={blogSection} className="center-content" />
          <Fade tag="div" bottom>
            <div className={splitClasses}>
              {posts.length && posts.map(item => <Post post={item} />)}
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

BlogPosts.propTypes = propTypes;
BlogPosts.defaultProps = defaultProps;

export default BlogPosts;
