import createDataContext from '../createDataContext';

const initialState = {
  isIntroLoad: false,
};

const loadDataReducer = (state, action) => {
  switch (action.type) {
    case 'set_intro_load': {
      return {
        ...state,
        isIntroLoad: true,
      };
    }
    case 'set_core_values_load': {
      return {
        ...state,
        isCoreValuesLoad: true,
      };
    }
    default:
      return { ...state };
  }
};

const setIntroLoad = dispatch => async () => {
  dispatch({ type: 'set_intro_load' });
};
const setCoreValuesLoad = dispatch => async () => {
  dispatch({ type: 'set_core_values_load' });
};

export const { Context, Provider } = createDataContext(
  loadDataReducer,
  { setIntroLoad, setCoreValuesLoad },
  initialState,
);
