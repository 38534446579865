import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Context as userContext } from '../../../context/userContext';

const FooterNav = ({ className, ...props }) => {
  const classes = classNames('footer-nav', className);
  const { state: currentUser, fetchUserData } = useContext(userContext);
  useEffect(() => {
    fetchUserData();
  }, []);
  const location = useLocation();

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link to="/privacy/">Privacy</Link>
        </li>
        {/* {location.pathname !== '/resume/' && ( */}
        {/* <li> */}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {/* <Link to="/resume/">Resume</Link> */}
        {/* </li> */}
        {/* )} */}
        {location.pathname !== '/' && (
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="/">Home</Link>
          </li>
        )}
        {location.pathname !== '/blog/' && (
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="/blog/">Blog</Link>
          </li>
        )}
        {location.pathname === '/blog/' && (
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link to="/blog/posts/">Posts</Link>
          </li>
        )}
        {currentUser && (currentUser.isStaff || currentUser.isSuperuser) && (
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => {
                window.location.href = '/admin/';
              }}
            >
              Admin
            </Link>
          </li>
        )}
        <li>
          <a href="mailto:public@vadymkhodak.com">Contact me</a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
