import React from 'react';

const CourageIcon = () => (
  <svg width="64" height="64" xmlns="http://www.w3.org/2000/svg">
    <title>Courage</title>
    <g strokeWidth="2" fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        stroke="#FFF"
        d="M30 39.313l-4.18 2.197L27 34.628l-5-4.874 6.91-1.004L32 22.49l3.09 6.26L42 29.754l-3 2.924"
      />
      <path d="M43 42h-9M43 37h-9" stroke="#ACADFF" />
    </g>
  </svg>
);

export default CourageIcon;
