import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { loadBlogPosts, loadBlogPostDetails } from '../../api';
import { SectionSplitProps } from '../../utils/SectionProps';
import SyntaxHighlighterComponent from '../common/SyntaxHighlighterComponent';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const PostDetails = (
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const postSlug = useLocation().pathname.split('/')[3];
  const [post, setPost] = useState(false);
  const [postId, setPostId] = useState(false);
  useEffect(() => {
    loadBlogPosts({ slug: [postSlug] }).then(response => {
      setPostId(response.results.length > 0 && response.results[0].id);
    });
  }, [postSlug]);
  useEffect(() => {
    if (postId) {
      loadBlogPostDetails(postId).then(response => {
        setPost(response);
      });
    }
  }, [postId]);

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {post && (
            <div {...props} className="section-header">
              <div className="container-m">
                <h2
                  className={classNames(
                    'mt-0',
                    post.markdown_text ? 'mb-16' : 'mb-0',
                  )}
                >
                  {post.name}
                </h2>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {post.subtitle}
                </div>

                <Image
                  className="has-shadow"
                  style={{ margin: '0 auto' }}
                  src={post.image}
                  alt="Intro"
                  width={896}
                  height={504}
                />
                <p className="m-0 post-text" style={{ textAlign: 'inherit' }}>
                  <ReactMarkdown
                    components={SyntaxHighlighterComponent}
                    children={post.markdown_text}
                  />
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

PostDetails.propTypes = propTypes;
PostDetails.defaultProps = defaultProps;

export default PostDetails;
