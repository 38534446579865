import React, { useEffect, useRef } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import Home from './views/Home';
import Policy from './views/Policy';
import Sign from './views/Sign';
import Resume from './views/Resume';
import Blog from './views/Blog';
import Posts from './views/Posts';
import Post from './views/Post';

const App = () => {
  const childRef = useRef();
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded');
    childRef.current.init();
  }, [location]);

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/signin"
              component={Sign}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/signup"
              component={Sign}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/privacy"
              component={Policy}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/privacy/ua"
              component={Policy}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/resume"
              component={Resume}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/blog"
              component={Blog}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/blog/posts"
              component={Posts}
              layout={LayoutDefault}
            />
            <AppRoute
              path="/blog/posts/:slug"
              component={Post}
              layout={LayoutDefault}
            />
          </Switch>
        )}
      />
    </>
  );
};

export default App;
