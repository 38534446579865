import React from 'react';

const LinkedInIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16.91 16.16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>LinkedIn</title>
    <path d="M17.19,10.24v6.18H13.61V10.65c0-1.45-.52-2.44-1.81-2.44A2,2,0,0,0,10,9.53a2.45,2.45,0,0,0-.12.87v6H6.26s0-9.76,0-10.77H9.84V7.17l0,0h0v0a3.56,3.56,0,0,1,3.23-1.78C15.42,5.39,17.19,6.93,17.19,10.24ZM2.51,0.45a1.87,1.87,0,1,0,0,3.72h0A1.87,1.87,0,1,0,2.51.45Zm-1.81,16H4.28V5.64H0.69V16.42Z" />
  </svg>
);

export default LinkedInIcon;
