/* eslint-disable no-restricted-syntax */
import React, { useReducer } from 'react';

export default (reducer, actions, initialState) => {
  const Context = React.createContext();
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const boundActions = {};
    // eslint-disable-next-line guard-for-in
    for (const key in actions) {
      boundActions[key] = actions[key](dispatch, state);
    }

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
};
