import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import SyntaxHighlighterComponent from '../../common/SyntaxHighlighterComponent';
import Image from '../../elements/Image';

// TODO: fix is-revealed
const Post = ({ post, imageFill }) => {
  const className = 'split-item-content center-content-mobile';
  const detailPath = `/blog/posts/${post.slug}/`;
  return (
    <Link to={detailPath} key={post.name}>
      <div className="split-item">
        <div className={className}>
          <h3 className="mt-0 mb-12">{post.name}</h3>
          <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
            {post.subtitle}
          </div>
          <p className="m-0">
            <ReactMarkdown
              components={SyntaxHighlighterComponent}
              children={post.markdown_short_text || post.markdown_text}
            />
          </p>
        </div>
        <div
          className={classNames(
            'split-item-image center-content-mobile',
            imageFill && 'split-item-image-fill',
          )}
        >
          <Image src={post.image} alt={post.title} width={528} height={396} />
        </div>
      </div>
    </Link>
  );
};

export default Post;
