import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Context as homeContext } from '../../context/homeContext';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Recommendation from './partials/Recommendation';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Recommendations = ({
  className,
  sections,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

  const {
    state: { recommendations },
    fetchRecommendationsData,
  } = useContext(homeContext);
  useEffect(() => {
    fetchRecommendationsData();
  }, []);
  const recommendationsSection =
    sections &&
    sections.length &&
    sections.filter(item => item.type === 'recommendation')[0];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={recommendationsSection}
            className="center-content"
          />
          <div className={tilesClasses}>
            {recommendations.length &&
              recommendations.map((item, key) => (
                <Recommendation data={item} num={key} />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Recommendations.propTypes = propTypes;
Recommendations.defaultProps = defaultProps;

export default Recommendations;
